import tattoo from './assets/tattoo.svg';
import rose from './assets/rose.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div >
        <h1 color='white'>
          Katelyn Eve
        </h1>
        <h3 color='white'>
            I'm lucky to have you in my life<br></br>
            I love you so very much<br></br>
            ~ Love, Kyle Gene
        </h3>
    </div>

        {/* <img src={rose} className="App-logo" alt="logo" /> */}
        <img src={tattoo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
